<template>
    <div>
        <div class="content-1 px-5 d-none d-md-block ">
            <div class="row align-items-md-end align-items-end h-100 pb-4 pb-md-0 h-100">
                <div class="col-12 col-lg-6 col-xl-8 h-75">
                    <div class=" text-center text-md-left h-50">
                        <div v-for=" (message,index) in $t('landing.messages') " :key="index">
                            <div class="animated fadeIn " v-show=" index == sliderIndex ">
                                <h3 class="f-44 d-none d-md-block" v-html="message.title "> </h3>
                                <h3 class="f-22 d-md-none" v-html="message.title "> </h3>
                                <p class="animated flipInX mb-4 ">
                                    <span class="f-w-600" v-text="  message.subtitle  "> </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <p class="text-center text-md-left">
                        <b-button pill variant="success" class="px-4" size="lg" @click="signUp()">
                            <span class="f-w-700 f-16" v-text="$t('landing.startSelling')"></span>
                        </b-button>
                    </p>
                </div>
            </div>
        </div>
        <div
            class="d-md-none d-flex flex-column justify-content-between bg-black text-white py-5 px-3 content-1-mobile">
            <img src="@/assets/images/ecart-logo.png" class="img-contain-200 m-auto animated fadeIn">
            <div class="row justify-content-center animated fadeInUp">
                <div class="col-7" v-if="user.id ==  null">
                    <b-button pill variant="outline-light" size="lg" @click="signIn()" class="mb-3 w-100">
                        <span class="f-w-600 f-14 text-white" v-text=" $t( 'general.login') "></span>
                    </b-button> <br>
                    <b-button pill variant="success" size="lg" @click="signUp()" class="mb-3 w-100">
                        <span class="f-w-600 f-14" v-text=" $t( 'general.signup') "></span>
                    </b-button>
                    <br>
                    <b-button variant="link text-white  w-100 " @click="fnGlobalScrollTo('moreInfo','start')" v-text="$t('general.button.moreInfo')">
                    </b-button>
                </div>
                <div v-else>
                    <b-button pill variant="success" size="lg" to="/dashboard" class="w-100">
                        <i class="fa fa-tachometer-alt mr-1"></i>
                        <span v-text=" $t('general.dashboard')"></span>
                    </b-button>
                </div>
            </div>
            <div class=" float-top-right p-4">
                <Languages variant='none text-white' />
            </div>
        </div>
    </div>

</template>

<script>
    import Languages from "@/components/Languages";
    import {
        mapState
    } from 'vuex';
    export default {
        components: {
            Languages,
        },
        data() {
            return ({
                homeBoxSliderMessage: [],
                sliderIndex: 0,
            })
        },
        computed: {
            ...mapState('session', ['user'])
        },
        methods: {
            fnChangeSlider() {
                if (this.sliderIndex < (this.homeBoxSliderMessage.length - 1)) {
                    this.sliderIndex++;
                } else {
                    this.sliderIndex = 0;
                }
                setTimeout(this.fnChangeSlider, 4000);
            },
            signIn() {
                window.location.href =`${process.env.VUE_APP_ECART_API}signin`;
            },
            signUp() {
                window.location.href =`${process.env.VUE_APP_ECART_API}signup`;
            }
        }
    }
</script>

<style lang="scss">
    .content-1 {
        color: white;
        // padding: 0rem 4rem;
        height: 500px;
        background-image: url('../../../../assets/images/landing/onda_gradient.png');
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position-x: right;
        background-position-y: bottom;
        background-color: black;
    }

    .content-1-mobile {
        min-height: 100vh;
        background-image: url('../../../../assets/images/backgrounds/bg-1.png');
        background-size: cover;
    }
</style>