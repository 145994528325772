<template>
  <div class="">
    <HomeSection1 />

    <div class="d-lg-none text-center" v-if="deviceToken == null">
      <p class="text-right">
        <img src="@/assets/images/landing/mobile_wave.png" class="img-fluid mb-4" alt="">
      </p>
      <h3 class="f-34">
        <strong v-html="$tc('messages.ecartSellerApp', 1 , {color: 'text-success' })"></strong>
      </h3>
      <h4 class="f-28 text-success">
        <strong class="text-success" v-text="$t('messages.downloadNow')"></strong>
      </h4>
      <p class="f-w-600" v-text="$t('messages.manageYourStore')"></p>
      <div class="row justify-content-center my-4">
        <div class="col-5 col-sm-4 col-md-3">
          <a href="https://play.google.com/store/apps/details?id=com.ecart.seller" target="_blank">
            <img src="@/assets/images/landing/icon_playstore.svg" alt="">
          </a>
        </div>
        <div class="col-5 col-sm-4 col-md-3">
          <a href="https://apps.apple.com/mx/app/ecart-seller/id1559326106" target="_blank">
            <img src="@/assets/images/landing/icon_appstore.svg" alt="">
          </a>
        </div>
      </div>
    </div>

    <div id="moreInfo"></div>
    <div class="d-lg-none mt-5">
      <h3 class="text-center f-36 mb-5">
        <strong v-html="$t('landing.title1')"></strong>
      </h3>
      <div class="pl-md-5 mb-5">
        <div class="row justify-content-center">
          <div class="col-11 col-md-6 col-lg-2 order-2 order-md-1 mb-5 mb-lg-0 ">
            <div class="card shadow h-100 m-0">
              <div class="card-body">
                <h3 class="text-center mb-3 f-18">
                  <strong v-text=" $t('landing.cardTitle1') "></strong>
                </h3>
                <p class="text-center">
                  <img loading="lazy" src="@/assets/images/icons/discounts.svg" class=" img-contain-80 border-0 "
                    alt="">
                </p>
                <p v-text=" $t('landing.cardMessage1') "> </p>
              </div>
            </div>
          </div>
          <div class="col-11 col-md-6 col-lg-2 order-2 order-md-1 mb-5 mb-lg-0 ">
            <div class="card shadow h-100 m-0">
              <div class="card-body">
                <h3 class="text-center mb-3 f-18">
                  <strong v-text=" $t('landing.cardTitle2') "></strong>
                </h3>
                <p class="text-center">
                  <img loading="lazy" src="@/assets/images/icons/tools.svg" class=" img-contain-80 border-0 " alt="">
                </p>
                <p v-text=" $t('landing.cardMessage2') "> </p>
              </div>
            </div>
          </div>
          <div class="col-11 col-md-6 col-lg-2 order-2 order-md-1 mb-5 mb-lg-0 ">
            <div class="card shadow h-100 m-0">
              <div class="card-body">
                <h3 class="text-center mb-3 f-18">
                  <strong v-text=" $t('landing.cardTitle3') "></strong>
                </h3>
                <p class="text-center">
                  <img loading="lazy" src="@/assets/images/icons/support.svg" class=" img-contain-80 border-0 " alt="">
                </p>
                <p v-text=" $t('landing.cardMessage3') "> </p>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-md order-1 order-md-2 mb-5 mb-lg-0 d-flex flex-column justify-content-center bg-green text-white p-5">
            <p v-text=" $t('landing.subtitle1') " class="f-w-600 f-16">
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="content-2 d-none d-lg-block">
      <div class="slide animated fadeInRight h-100">
        <div class="h-100 d-flex flex-column justify-content-center">
          <h3 class="text-center f-36 mb-5">
            <strong v-html="$t('landing.title1')"></strong>
          </h3>
          <div class="pl-md-5 mb-5">
            <div class="row justify-content-center">
              <div class="col-11 col-md-6 col-lg-2 order-2 order-md-1 mb-5 mb-lg-0 ">
                <div class="card shadow h-100 m-0">
                  <div class="card-body">
                    <h3 class="text-center mb-3 f-18">
                      <strong v-text=" $t('landing.cardTitle1') "></strong>
                    </h3>
                    <p class="text-center">
                      <img loading="lazy" src="@/assets/images/icons/discounts.svg" class=" img-contain-80 border-0 "
                        alt="">
                    </p>
                    <p v-text=" $t('landing.cardMessage1') "> </p>
                  </div>
                </div>
              </div>
              <div class="col-11 col-md-6 col-lg-2 order-2 order-md-1 mb-5 mb-lg-0 ">
                <div class="card shadow h-100 m-0">
                  <div class="card-body">
                    <h3 class="text-center mb-3 f-18">
                      <strong v-text=" $t('landing.cardTitle2') "></strong>
                    </h3>
                    <p class="text-center">
                      <img loading="lazy" src="@/assets/images/icons/tools.svg" class=" img-contain-80 border-0 "
                        alt="">
                    </p>
                    <p v-text=" $t('landing.cardMessage2') "> </p>
                  </div>
                </div>
              </div>
              <div class="col-11 col-md-6 col-lg-2 order-2 order-md-1 mb-5 mb-lg-0 ">
                <div class="card shadow h-100 m-0">
                  <div class="card-body">
                    <h3 class="text-center mb-3 f-18">
                      <strong v-text=" $t('landing.cardTitle3') "></strong>
                    </h3>
                    <p class="text-center">
                      <img loading="lazy" src="@/assets/images/icons/support.svg" class=" img-contain-80 border-0 "
                        alt="">
                    </p>
                    <p v-text=" $t('landing.cardMessage3') "> </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md order-1 order-md-2 mb-5 mb-lg-0 d-flex ">
                <div class="flex-column justify-content-center bg-green text-white p-5">
                  <p v-text=" $t('landing.subtitle1') " class="f-w-600 f-16">
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="slide animated fadeInRight mobile-content h-100">

        <div class="row h-100">
          <div class="col-6 text-center h-100 d-flex flex-column justify-content-center ">
            <h3 class="f-40">
              <strong v-html="$tc('messages.ecartSellerApp', 1 , {color: 'text-success' })"></strong>
            </h3>
            <h4 class="f-34 text-success mb-4">
              <strong class="text-success" v-text="$t('messages.downloadNow')"></strong>
            </h4>
            <p class="f-w-600 f-18" v-text="$t('messages.manageYourStore')"></p>

            <div class="row justify-content-center my-4">
              <div class="col-4 col-xl-3">
                <a href="https://play.google.com/store/apps/details?id=com.ecart.seller" target="_blank">
                  <img src="@/assets/images/landing/icon_playstore.svg" alt="">
                </a>
              </div>
              <div class="col-4 col-xl-3">
                <a href="https://apps.apple.com/mx/app/ecart-seller/id1559326106" target="_blank">
                  <img src="@/assets/images/landing/icon_appstore.svg" alt="">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div class="content-3 py-5 text-white">
      <h3 class="text-center f-36 mb-5">
        <strong v-html=" $t('landing.title2') "></strong>
      </h3>
      <div class="row justify-content-center align-items-center ">
        <div class="col-11 col-lg-4">
          <div class="row">
            <div class="col-12 py-4">
              <div class="row">
                <div class="col-12 col-md-auto text-center">
                  <img loading="lazy" src="@/assets/images/icons/stores.svg" class="img-contain-100 mb-3 mb-md-0"
                    alt="">
                </div>
                <div class="col-12 col-md">
                  <h4>
                    <strong v-text=" $t('landing.section2CardTitle1') "></strong>
                  </h4>
                  <div v-html=" $t('landing.section2CardMessage1') "></div>
                </div>
              </div>
            </div>
            <div class="col-12 py-4">
              <div class="row">
                <div class="col-12 col-md-auto text-center">
                  <img loading="lazy" src="@/assets/images/icons/products.svg" class="img-contain-100 mb-3 mb-md-0"
                    alt="">
                </div>
                <div class="col-12 col-md">
                  <h4>
                    <strong v-text=" $t('landing.section2CardTitle2') "></strong>
                  </h4>
                  <div v-html=" $t('landing.section2CardMessage2') "></div>
                </div>
              </div>
            </div>
            <div class="col-12 py-4">
              <div class="row">
                <div class="col-12 col-md-auto text-center">
                  <img loading="lazy" src="@/assets/images/icons/orders.svg" class="img-contain-100 mb-3 mb-md-0"
                    alt="">
                </div>
                <div class="col-12 col-md">
                  <h4>
                    <strong v-text=" $t('landing.section2CardTitle3') "></strong>
                  </h4>
                  <div v-html=" $t('landing.section2CardMessage3') "></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-11 col-lg-4">
          <div class="row">
            <div class="col-12 py-4">
              <div class="row">
                <div class="col-12 col-md-auto text-center">
                  <img loading="lazy" src="@/assets/images/icons/shipment.svg" class="img-contain-100 mb-3 mb-md-0"
                    alt="">
                </div>
                <div class="col-12 col-md">
                  <h4>
                    <strong v-text=" $t('landing.section2CardTitle4') "></strong>
                  </h4>
                  <div v-html=" $t('landing.section2CardMessage4') "></div>
                </div>
              </div>
            </div>
            <div class="col-12 py-4">
              <div class="row">
                <div class="col-12 col-md-auto text-center">
                  <img loading="lazy" src="@/assets/images/icons/payments.svg" class="img-contain-100 mb-3 mb-md-0"
                    alt="">
                </div>
                <div class="col-12 col-md">
                  <h4>
                    <strong v-text=" $t('landing.section2CardTitle5') "></strong>
                  </h4>
                  <div v-html=" $t('landing.section2CardMessage5') "></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-4 text-white py-5">
      <h3 class="text-center f-36 mb-5" id="title3">
        <strong v-html=" $t('landing.title3') "></strong>
      </h3>
      <div class="row align-items-center justify-content-center my-5">
        <div class="col-11 col-md-4">
          <h5 class="mb-4" v-text=" $t('landing.section3CardTitle1') "> </h5>
          <h5 class="mb-4" v-text=" $t('landing.section3CardMessage1') "></h5>
          <h5 class="mb-4" v-text=" $t('landing.section3CardTitle2') "> </h5>

          <div class="row d-md-none mb-4">
            <div class="col-6">
              <p class="text-center">
                <img loading="lazy" src="@/assets/images/icons/listing-fee.svg" class=" img-contain-150  " alt=""><br>
                <span v-text="$t('landing.section3CardCircle1')"></span>
              </p>
            </div>
            <div class="col-6">
              <p class="text-center">
                <img loading="lazy" src="@/assets/images/icons/selling-fee.svg" class=" img-contain-150 " alt=""><br>
                <span v-text="$t('landing.section3CardCircle2')"></span>
              </p>

            </div>
          </div>

          <div class="row">
            <div class="col-auto pr-0">
              <i class="fa fa-check-circle text-success"></i>
            </div>
            <div class="col">
              <span v-text="$t('landing.section3CardMessage2List1') "> </span>
            </div>
          </div>

          <div class="row">
            <div class="col-auto pr-0">
              <i class="fa fa-check-circle text-success"></i>
            </div>
            <div class="col">
              <span v-text="$t('landing.section3CardMessage2List2') "> </span>
            </div>
          </div>

          <div class="row">
            <div class="col-auto pr-0">
              <i class="fa fa-check-circle text-success"></i>
            </div>
            <div class="col">
              <span v-text="$t('landing.section3CardMessage2List3') "> </span>
            </div>
          </div>

        </div>
        <div class="col-6 col-md-4 d-none d-md-block">
          <div class="row">
            <div class="col-6">
              <p class="text-center">
                <img loading="lazy" src="@/assets/images/icons/listing-fee.svg" class=" img-contain-200 " alt=""><br>
                <span v-text="$t('landing.section3CardCircle1')"></span>
              </p>
            </div>
            <div class="col-6 selling-fee">
              <p class="text-center">
                <img loading="lazy" src="@/assets/images/icons/selling-fee.svg" class=" img-contain-200 " alt=""><br>
                <span v-text="$t('landing.section3CardCircle2')"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fixed-bottom-right p-4">
      <transition name="custom-classes-transition" enter-active-class="animated bounceInRight"
        leave-active-class="animated bounceOutRight">
        <button class="btn btn-primary circle-50 " v-if="showScrollBtn" @click="fnScrollTop()">
          <i class="fa fa-arrow-alt-circle-up fa-2x "></i>
        </button>
      </transition>
    </div>

  </div>
</template>

<script>
  import {
    mapState,
  } from 'vuex';
  import HomeSection1 from "./components/home-section-1";
  export default {
    components: {
      HomeSection1,
    },
    data() {
      return ({
        homeBoxSliderMessage: [],
        sliderIndex: 0,
        slideIndex: 0,
        intervalId: null,
        showScrollBtn: false,
      })
    },
    computed: {
      ...mapState(['deviceToken']),
    },
    methods: {
      fnScrollTop() {
        document.getElementById('app').scrollIntoView({
          behavior: "smooth"
        });
      },

      fnChangeSlider() {
        if (this.sliderIndex < (this.homeBoxSliderMessage.length - 1)) {
          this.sliderIndex++;
        } else {
          this.sliderIndex = 0;
        }
        setTimeout(this.fnChangeSlider, 4000);
      },

      fnCarousel() {
        var slides = document.getElementsByClassName("slide");
        for (let index = 0; index < slides.length; index++) {
          slides[index].style.display = "none";
        }
        this.slideIndex++;
        if (this.slideIndex > slides.length) {
          this.slideIndex = 1
        }
        slides[this.slideIndex - 1].style.display = "block";
      }

    },

    mounted() {
      this.homeBoxSliderMessage = this.$t('landing.messages');
      this.fnChangeSlider();
      this.fnCarousel();
      this.intervalId = setInterval(() => {
        this.fnCarousel();
      }, 4000);

      document.getElementsByTagName('body')[0].addEventListener('scroll', (e) => {
        if (e.target.scrollTop > 0) {
          this.showScrollBtn = true;
        } else {
          this.showScrollBtn = false;
        }
      });
    },
    beforeDestroy() {
      if (this.intervalId != null) {
        clearInterval(this.intervalId);
      }
    }

  }
</script>


<style lang="scss">
  .content-2 {
    height: 528px;

    .mobile-content {
      background-image: url('../../../assets/images/landing/mobile_wave.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position-x: right;
      background-position-y: top;
      background-color: white;
    }
  }

  .content-3 {
    background-color: #00271B;
  }

  .content-4 {
    background-color: #141214;

    .selling-fee {
      margin-top: 100px;
    }
  }

  .bg-green {
    background-color: #43ad00;
  }
</style>